export const countryCodes = [
  {
    code: "AC",
    dial_code: "+247",
    number: "247",
    name: "Ascension Island",
  },
  {
    code: "AD",
    dial_code: "+376",
    number: "376",
    name: "Andorra",
  },
  {
    code: "AE",
    dial_code: "+971",
    number: "971",
    name: "United Arab Emirates",
  },
  {
    code: "AF",
    dial_code: "+93",
    number: "93",
    name: "Afghanistan",
  },
  {
    code: "AG",
    dial_code: "+1",
    number: "1",
    name: "Antigua and Barbuda",
  },
  {
    code: "AI",
    dial_code: "+1",
    number: "1",
    name: "Anguilla",
  },
  {
    code: "AL",
    dial_code: "+355",
    number: "355",
    name: "Albania",
  },
  {
    code: "AM",
    dial_code: "+374",
    number: "374",
    name: "Armenia",
  },
  {
    code: "AO",
    dial_code: "+244",
    number: "244",
    name: "Angola",
  },
  {
    code: "AR",
    dial_code: "+54",
    number: "54",
    name: "Argentina",
  },
  {
    code: "AS",
    dial_code: "+1",
    number: "1",
    name: "American Samoa",
  },
  {
    code: "AT",
    dial_code: "+43",
    number: "43",
    name: "Austria",
  },
  {
    code: "AU",
    dial_code: "+61",
    number: "61",
    name: "Australia",
  },
  {
    code: "AW",
    dial_code: "+297",
    number: "297",
    name: "Aruba",
  },
  {
    code: "AX",
    dial_code: "+358",
    number: "358",
    name: "Åland Islands",
  },
  {
    code: "AZ",
    dial_code: "+994",
    number: "994",
    name: "Azerbaijan",
  },
  {
    code: "BA",
    dial_code: "+387",
    number: "387",
    name: "Bosnia and Herzegovina",
  },
  {
    code: "BB",
    dial_code: "+1",
    number: "1",
    name: "Barbados",
  },
  {
    code: "BD",
    dial_code: "+880",
    number: "880",
    name: "Bangladesh",
  },
  {
    code: "BE",
    dial_code: "+32",
    number: "32",
    name: "Belgium",
  },
  {
    code: "BF",
    dial_code: "+226",
    number: "226",
    name: "Burkina Faso",
  },
  {
    code: "BG",
    dial_code: "+359",
    number: "359",
    name: "Bulgaria",
  },
  {
    code: "BH",
    dial_code: "+973",
    number: "973",
    name: "Bahrain",
  },
  {
    code: "BI",
    dial_code: "+257",
    number: "257",
    name: "Burundi",
  },
  {
    code: "BJ",
    dial_code: "+229",
    number: "229",
    name: "Benin",
  },
  {
    code: "BL",
    dial_code: "+590",
    number: "590",
    name: "Saint Barthélemy",
  },
  {
    code: "BM",
    dial_code: "+1",
    number: "1",
    name: "Bermuda",
  },
  {
    code: "BN",
    dial_code: "+673",
    number: "673",
    name: "Brunei Darussalam",
  },
  {
    code: "BO",
    dial_code: "+591",
    number: "591",
    name: "Bolivia",
  },
  {
    code: "BQ",
    dial_code: "+599",
    number: "599",
    name: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "BR",
    dial_code: "+55",
    number: "55",
    name: "Brazil",
  },
  {
    code: "BS",
    dial_code: "+1",
    number: "1",
    name: "Bahamas",
  },
  {
    code: "BT",
    dial_code: "+975",
    number: "975",
    name: "Bhutan",
  },
  {
    code: "BW",
    dial_code: "+267",
    number: "267",
    name: "Botswana",
  },
  {
    code: "BY",
    dial_code: "+375",
    number: "375",
    name: "Belarus",
  },
  {
    code: "BZ",
    dial_code: "+501",
    number: "501",
    name: "Belize",
  },
  {
    code: "CA",
    dial_code: "+1",
    number: "1",
    name: "Canada",
  },
  {
    code: "CC",
    dial_code: "+61",
    number: "61",
    name: "Cocos (Keeling) Islands",
  },
  {
    code: "CD",
    dial_code: "+243",
    number: "243",
    name: "Congo, Democratic Republic of the",
  },
  {
    code: "CF",
    dial_code: "+236",
    number: "236",
    name: "Central African Republic",
  },
  {
    code: "CG",
    dial_code: "+242",
    number: "242",
    name: "Congo",
  },
  {
    code: "CH",
    dial_code: "+41",
    number: "41",
    name: "Switzerland",
  },
  {
    code: "CI",
    dial_code: "+225",
    number: "225",
    name: "Cote d'Ivoire",
  },
  {
    code: "CK",
    dial_code: "+682",
    number: "682",
    name: "Cook Islands",
  },
  {
    code: "CL",
    dial_code: "+56",
    number: "56",
    name: "Chile",
  },
  {
    code: "CM",
    dial_code: "+237",
    number: "237",
    name: "Cameroon",
  },
  {
    code: "CN",
    dial_code: "+86",
    number: "86",
    name: "China",
  },
  {
    code: "CO",
    dial_code: "+57",
    number: "57",
    name: "Colombia",
  },
  {
    code: "CR",
    dial_code: "+506",
    number: "506",
    name: "Costa Rica",
  },
  {
    code: "CU",
    dial_code: "+53",
    number: "53",
    name: "Cuba",
  },
  {
    code: "CV",
    dial_code: "+238",
    number: "238",
    name: "Cape Verde",
  },
  {
    code: "CW",
    dial_code: "+599",
    number: "599",
    name: "Curaçao",
  },
  {
    code: "CX",
    dial_code: "+61",
    number: "61",
    name: "Christmas Island",
  },
  {
    code: "CY",
    dial_code: "+357",
    number: "357",
    name: "Cyprus",
  },
  {
    code: "CZ",
    dial_code: "+420",
    number: "420",
    name: "Czech Republic",
  },
  {
    code: "DE",
    dial_code: "+49",
    number: "49",
    name: "Germany",
  },
  {
    code: "DJ",
    dial_code: "+253",
    number: "253",
    name: "Djibouti",
  },
  {
    code: "DK",
    dial_code: "+45",
    number: "45",
    name: "Denmark",
  },
  {
    code: "DM",
    dial_code: "+1",
    number: "1",
    name: "Dominica",
  },
  {
    code: "DO",
    dial_code: "+1",
    number: "1",
    name: "Dominican Republic",
  },
  {
    code: "DZ",
    dial_code: "+213",
    number: "213",
    name: "Algeria",
  },
  {
    code: "EC",
    dial_code: "+593",
    number: "593",
    name: "Ecuador",
  },
  {
    code: "EE",
    dial_code: "+372",
    number: "372",
    name: "Estonia",
  },
  {
    code: "EG",
    dial_code: "+20",
    number: "20",
    name: "Egypt",
  },
  {
    code: "EH",
    dial_code: "+212",
    number: "212",
    name: "Western Sahara",
  },
  {
    code: "ER",
    dial_code: "+291",
    number: "291",
    name: "Eritrea",
  },
  {
    code: "ES",
    dial_code: "+34",
    number: "34",
    name: "Spain",
  },
  {
    code: "ET",
    dial_code: "+251",
    number: "251",
    name: "Ethiopia",
  },
  {
    code: "FI",
    dial_code: "+358",
    number: "358",
    name: "Finland",
  },
  {
    code: "FJ",
    dial_code: "+679",
    number: "679",
    name: "Fiji",
  },
  {
    code: "FK",
    dial_code: "+500",
    number: "500",
    name: "Falkland Islands",
  },
  {
    code: "FM",
    dial_code: "+691",
    number: "691",
    name: "Federated States of Micronesia",
  },
  {
    code: "FO",
    dial_code: "+298",
    number: "298",
    name: "Faroe Islands",
  },
  {
    code: "FR",
    dial_code: "+33",
    number: "33",
    name: "France",
  },
  {
    code: "GA",
    dial_code: "+241",
    number: "241",
    name: "Gabon",
  },
  {
    code: "GB",
    dial_code: "+44",
    number: "44",
    name: "United Kingdom",
  },
  {
    code: "GD",
    dial_code: "+1",
    number: "1",
    name: "Grenada",
  },
  {
    code: "GE",
    dial_code: "+995",
    number: "995",
    name: "Georgia",
  },
  {
    code: "GF",
    dial_code: "+594",
    number: "594",
    name: "French Guiana",
  },
  {
    code: "GG",
    dial_code: "+44",
    number: "44",
    name: "Guernsey",
  },
  {
    code: "GH",
    dial_code: "+233",
    number: "233",
    name: "Ghana",
  },
  {
    code: "GI",
    dial_code: "+350",
    number: "350",
    name: "Gibraltar",
  },
  {
    code: "GL",
    dial_code: "+299",
    number: "299",
    name: "Greenland",
  },
  {
    code: "GM",
    dial_code: "+220",
    number: "220",
    name: "Gambia",
  },
  {
    code: "GN",
    dial_code: "+224",
    number: "224",
    name: "Guinea",
  },
  {
    code: "GP",
    dial_code: "+590",
    number: "590",
    name: "Guadeloupe",
  },
  {
    code: "GQ",
    dial_code: "+240",
    number: "240",
    name: "Equatorial Guinea",
  },
  {
    code: "GR",
    dial_code: "+30",
    number: "30",
    name: "Greece",
  },
  {
    code: "GT",
    dial_code: "+502",
    number: "502",
    name: "Guatemala",
  },
  {
    code: "GU",
    dial_code: "+1",
    number: "1",
    name: "Guam",
  },
  {
    code: "GW",
    dial_code: "+245",
    number: "245",
    name: "Guinea-Bissau",
  },
  {
    code: "GY",
    dial_code: "+592",
    number: "592",
    name: "Guyana",
  },
  {
    code: "HK",
    dial_code: "+852",
    number: "852",
    name: "Hong Kong",
  },
  {
    code: "HN",
    dial_code: "+504",
    number: "504",
    name: "Honduras",
  },
  {
    code: "HR",
    dial_code: "+385",
    number: "385",
    name: "Croatia",
  },
  {
    code: "HT",
    dial_code: "+509",
    number: "509",
    name: "Haiti",
  },
  {
    code: "HU",
    dial_code: "+36",
    number: "36",
    name: "Hungary",
  },
  {
    code: "ID",
    dial_code: "+62",
    number: "62",
    name: "Indonesia",
  },
  {
    code: "IE",
    dial_code: "+353",
    number: "353",
    name: "Ireland",
  },
  {
    code: "IL",
    dial_code: "+972",
    number: "972",
    name: "Israel",
  },
  {
    code: "IM",
    dial_code: "+44",
    number: "44",
    name: "Isle of Man",
  },
  {
    code: "IN",
    dial_code: "+91",
    number: "91",
    name: "India",
  },
  {
    code: "IO",
    dial_code: "+246",
    number: "246",
    name: "British Indian Ocean Territory",
  },
  {
    code: "IQ",
    dial_code: "+964",
    number: "964",
    name: "Iraq",
  },
  {
    code: "IR",
    dial_code: "+98",
    number: "98",
    name: "Iran",
  },
  {
    code: "IS",
    dial_code: "+354",
    number: "354",
    name: "Iceland",
  },
  {
    code: "IT",
    dial_code: "+39",
    number: "39",
    name: "Italy",
  },
  {
    code: "JE",
    dial_code: "+44",
    number: "44",
    name: "Jersey",
  },
  {
    code: "JM",
    dial_code: "+1",
    number: "1",
    name: "Jamaica",
  },
  {
    code: "JO",
    dial_code: "+962",
    number: "962",
    name: "Jordan",
  },
  {
    code: "JP",
    dial_code: "+81",
    number: "81",
    name: "Japan",
  },
  {
    code: "KE",
    dial_code: "+254",
    number: "254",
    name: "Kenya",
  },
  {
    code: "KG",
    dial_code: "+996",
    number: "996",
    name: "Kyrgyzstan",
  },
  {
    code: "KH",
    dial_code: "+855",
    number: "855",
    name: "Cambodia",
  },
  {
    code: "KI",
    dial_code: "+686",
    number: "686",
    name: "Kiribati",
  },
  {
    code: "KM",
    dial_code: "+269",
    number: "269",
    name: "Comoros",
  },
  {
    code: "KN",
    dial_code: "+1",
    number: "1",
    name: "Saint Kitts and Nevis",
  },
  {
    code: "KP",
    dial_code: "+850",
    number: "850",
    name: "North Korea",
  },
  {
    code: "KR",
    dial_code: "+82",
    number: "82",
    name: "South Korea",
  },
  {
    code: "KW",
    dial_code: "+965",
    number: "965",
    name: "Kuwait",
  },
  {
    code: "KY",
    dial_code: "+1",
    number: "1",
    name: "Cayman Islands",
  },
  {
    code: "KZ",
    dial_code: "+7",
    number: "7",
    name: "Kazakhstan",
  },
  {
    code: "LA",
    dial_code: "+856",
    number: "856",
    name: "Laos",
  },
  {
    code: "LB",
    dial_code: "+961",
    number: "961",
    name: "Lebanon",
  },
  {
    code: "LC",
    dial_code: "+1",
    number: "1",
    name: "Saint Lucia",
  },
  {
    code: "LI",
    dial_code: "+423",
    number: "423",
    name: "Liechtenstein",
  },
  {
    code: "LK",
    dial_code: "+94",
    number: "94",
    name: "Sri Lanka",
  },
  {
    code: "LR",
    dial_code: "+231",
    number: "231",
    name: "Liberia",
  },
  {
    code: "LS",
    dial_code: "+266",
    number: "266",
    name: "Lesotho",
  },
  {
    code: "LT",
    dial_code: "+370",
    number: "370",
    name: "Lithuania",
  },
  {
    code: "LU",
    dial_code: "+352",
    number: "352",
    name: "Luxembourg",
  },
  {
    code: "LV",
    dial_code: "+371",
    number: "371",
    name: "Latvia",
  },
  {
    code: "LY",
    dial_code: "+218",
    number: "218",
    name: "Libya",
  },
  {
    code: "MA",
    dial_code: "+212",
    number: "212",
    name: "Morocco",
  },
  {
    code: "MC",
    dial_code: "+377",
    number: "377",
    name: "Monaco",
  },
  {
    code: "MD",
    dial_code: "+373",
    number: "373",
    name: "Moldova",
  },
  {
    code: "ME",
    dial_code: "+382",
    number: "382",
    name: "Montenegro",
  },
  {
    code: "MF",
    dial_code: "+590",
    number: "590",
    name: "Saint Martin (French Part)",
  },
  {
    code: "MG",
    dial_code: "+261",
    number: "261",
    name: "Madagascar",
  },
  {
    code: "MH",
    dial_code: "+692",
    number: "692",
    name: "Marshall Islands",
  },
  {
    code: "MK",
    dial_code: "+389",
    number: "389",
    name: "North Macedonia",
  },
  {
    code: "ML",
    dial_code: "+223",
    number: "223",
    name: "Mali",
  },
  {
    code: "MM",
    dial_code: "+95",
    number: "95",
    name: "Myanmar",
  },
  {
    code: "MN",
    dial_code: "+976",
    number: "976",
    name: "Mongolia",
  },
  {
    code: "MO",
    dial_code: "+853",
    number: "853",
    name: "Macao",
  },
  {
    code: "MP",
    dial_code: "+1",
    number: "1",
    name: "Northern Mariana Islands",
  },
  {
    code: "MQ",
    dial_code: "+596",
    number: "596",
    name: "Martinique",
  },
  {
    code: "MR",
    dial_code: "+222",
    number: "222",
    name: "Mauritania",
  },
  {
    code: "MS",
    dial_code: "+1",
    number: "1",
    name: "Montserrat",
  },
  {
    code: "MT",
    dial_code: "+356",
    number: "356",
    name: "Malta",
  },
  {
    code: "MU",
    dial_code: "+230",
    number: "230",
    name: "Mauritius",
  },
  {
    code: "MV",
    dial_code: "+960",
    number: "960",
    name: "Maldives",
  },
  {
    code: "MW",
    dial_code: "+265",
    number: "265",
    name: "Malawi",
  },
  {
    code: "MX",
    dial_code: "+52",
    number: "52",
    name: "Mexico",
  },
  {
    code: "MY",
    dial_code: "+60",
    number: "60",
    name: "Malaysia",
  },
  {
    code: "MZ",
    dial_code: "+258",
    number: "258",
    name: "Mozambique",
  },
  {
    code: "NA",
    dial_code: "+264",
    number: "264",
    name: "Namibia",
  },
  {
    code: "NC",
    dial_code: "+687",
    number: "687",
    name: "New Caledonia",
  },
  {
    code: "NE",
    dial_code: "+227",
    number: "227",
    name: "Niger",
  },
  {
    code: "NF",
    dial_code: "+672",
    number: "672",
    name: "Norfolk Island",
  },
  {
    code: "NG",
    dial_code: "+234",
    number: "234",
    name: "Nigeria",
  },
  {
    code: "NI",
    dial_code: "+505",
    number: "505",
    name: "Nicaragua",
  },
  {
    code: "NL",
    dial_code: "+31",
    number: "31",
    name: "Netherlands",
  },
  {
    code: "NO",
    dial_code: "+47",
    number: "47",
    name: "Norway",
  },
  {
    code: "NP",
    dial_code: "+977",
    number: "977",
    name: "Nepal",
  },
  {
    code: "NR",
    dial_code: "+674",
    number: "674",
    name: "Nauru",
  },
  {
    code: "NU",
    dial_code: "+683",
    number: "683",
    name: "Niue",
  },
  {
    code: "NZ",
    dial_code: "+64",
    number: "64",
    name: "New Zealand",
  },
  {
    code: "OM",
    dial_code: "+968",
    number: "968",
    name: "Oman",
  },
  {
    code: "PA",
    dial_code: "+507",
    number: "507",
    name: "Panama",
  },
  {
    code: "PE",
    dial_code: "+51",
    number: "51",
    name: "Peru",
  },
  {
    code: "PF",
    dial_code: "+689",
    number: "689",
    name: "French Polynesia",
  },
  {
    code: "PG",
    dial_code: "+675",
    number: "675",
    name: "Papua New Guinea",
  },
  {
    code: "PH",
    dial_code: "+63",
    number: "63",
    name: "Philippines",
  },
  {
    code: "PK",
    dial_code: "+92",
    number: "92",
    name: "Pakistan",
  },
  {
    code: "PL",
    dial_code: "+48",
    number: "48",
    name: "Poland",
  },
  {
    code: "PM",
    dial_code: "+508",
    number: "508",
    name: "Saint Pierre and Miquelon",
  },
  {
    code: "PR",
    dial_code: "+1",
    number: "1",
    name: "Puerto Rico",
  },
  {
    code: "PS",
    dial_code: "+970",
    number: "970",
    name: "Palestine",
  },
  {
    code: "PT",
    dial_code: "+351",
    number: "351",
    name: "Portugal",
  },
  {
    code: "PW",
    dial_code: "+680",
    number: "680",
    name: "Palau",
  },
  {
    code: "PY",
    dial_code: "+595",
    number: "595",
    name: "Paraguay",
  },
  {
    code: "QA",
    dial_code: "+974",
    number: "974",
    name: "Qatar",
  },
  {
    code: "RE",
    dial_code: "+262",
    number: "262",
    name: "Reunion",
  },
  {
    code: "RO",
    dial_code: "+40",
    number: "40",
    name: "Romania",
  },
  {
    code: "RS",
    dial_code: "+381",
    number: "381",
    name: "Serbia",
  },
  {
    code: "RU",
    dial_code: "+7",
    number: "7",
    name: "Russia",
  },
  {
    code: "RW",
    dial_code: "+250",
    number: "250",
    name: "Rwanda",
  },
  {
    code: "SA",
    dial_code: "+966",
    number: "966",
    name: "Saudi Arabia",
  },
  {
    code: "SB",
    dial_code: "+677",
    number: "677",
    name: "Solomon Islands",
  },
  {
    code: "SC",
    dial_code: "+248",
    number: "248",
    name: "Seychelles",
  },
  {
    code: "SD",
    dial_code: "+249",
    number: "249",
    name: "Sudan",
  },
  {
    code: "SE",
    dial_code: "+46",
    number: "46",
    name: "Sweden",
  },
  {
    code: "SG",
    dial_code: "+65",
    number: "65",
    name: "Singapore",
  },
  {
    code: "SH",
    dial_code: "+290",
    number: "290",
    name: "Saint Helena",
  },
  {
    code: "SI",
    dial_code: "+386",
    number: "386",
    name: "Slovenia",
  },
  {
    code: "SJ",
    dial_code: "+47",
    number: "47",
    name: "Svalbard and Jan Mayen",
  },
  {
    code: "SK",
    dial_code: "+421",
    number: "421",
    name: "Slovakia",
  },
  {
    code: "SL",
    dial_code: "+232",
    number: "232",
    name: "Sierra Leone",
  },
  {
    code: "SM",
    dial_code: "+378",
    number: "378",
    name: "San Marino",
  },
  {
    code: "SN",
    dial_code: "+221",
    number: "221",
    name: "Senegal",
  },
  {
    code: "SO",
    dial_code: "+252",
    number: "252",
    name: "Somalia",
  },
  {
    code: "SR",
    dial_code: "+597",
    number: "597",
    name: "Suriname",
  },
  {
    code: "SS",
    dial_code: "+211",
    number: "211",
    name: "South Sudan",
  },
  {
    code: "ST",
    dial_code: "+239",
    number: "239",
    name: "Sao Tome and Principe",
  },
  {
    code: "SV",
    dial_code: "+503",
    number: "503",
    name: "El Salvador",
  },
  {
    code: "SX",
    dial_code: "+1",
    number: "1",
    name: "Sint Maarten",
  },
  {
    code: "SY",
    dial_code: "+963",
    number: "963",
    name: "Syria",
  },
  {
    code: "SZ",
    dial_code: "+268",
    number: "268",
    name: "Swaziland",
  },
  {
    code: "TC",
    dial_code: "+1",
    number: "1",
    name: "Turks and Caicos Islands",
  },
  {
    code: "TD",
    dial_code: "+235",
    number: "235",
    name: "Chad",
  },
  {
    code: "TG",
    dial_code: "+228",
    number: "228",
    name: "Togo",
  },
  {
    code: "TH",
    dial_code: "+66",
    number: "66",
    name: "Thailand",
  },
  {
    code: "TJ",
    dial_code: "+992",
    number: "992",
    name: "Tajikistan",
  },
  {
    code: "TK",
    dial_code: "+690",
    number: "690",
    name: "Tokelau",
  },
  {
    code: "TL",
    dial_code: "+670",
    number: "670",
    name: "Timor-Leste",
  },
  {
    code: "TM",
    dial_code: "+993",
    number: "993",
    name: "Turkmenistan",
  },
  {
    code: "TN",
    dial_code: "+216",
    number: "216",
    name: "Tunisia",
  },
  {
    code: "TO",
    dial_code: "+676",
    number: "676",
    name: "Tonga",
  },
  {
    code: "TR",
    dial_code: "+90",
    number: "90",
    name: "Turkey",
  },
  {
    code: "TT",
    dial_code: "+1",
    number: "1",
    name: "Trinidad and Tobago",
  },
  {
    code: "TV",
    dial_code: "+688",
    number: "688",
    name: "Tuvalu",
  },
  {
    code: "TW",
    dial_code: "+886",
    number: "886",
    name: "Taiwan",
  },
  {
    code: "TZ",
    dial_code: "+255",
    number: "255",
    name: "Tanzania",
  },
  {
    code: "UA",
    dial_code: "+380",
    number: "380",
    name: "Ukraine",
  },
  {
    code: "UG",
    dial_code: "+256",
    number: "256",
    name: "Uganda",
  },
  {
    code: "US",
    dial_code: "+1",
    number: "1",
    name: "United States",
  },
  {
    code: "UY",
    dial_code: "+598",
    number: "598",
    name: "Uruguay",
  },
  {
    code: "UZ",
    dial_code: "+998",
    number: "998",
    name: "Uzbekistan",
  },
  {
    code: "VA",
    dial_code: "+39",
    number: "39",
    name: "Holy See (Vatican City State)",
  },
  {
    code: "VC",
    dial_code: "+1",
    number: "1",
    name: "Saint Vincent and the Grenadines",
  },
  {
    code: "VE",
    dial_code: "+58",
    number: "58",
    name: "Venezuela",
  },
  {
    code: "VG",
    dial_code: "+1",
    number: "1",
    name: "Virgin Islands, British",
  },
  {
    code: "VI",
    dial_code: "+1",
    number: "1",
    name: "Virgin Islands, U.S.",
  },
  {
    code: "VN",
    dial_code: "+84",
    number: "84",
    name: "Vietnam",
  },
  {
    code: "VU",
    dial_code: "+678",
    number: "678",
    name: "Vanuatu",
  },
  {
    code: "WF",
    dial_code: "+681",
    number: "681",
    name: "Wallis and Futuna",
  },
  {
    code: "WS",
    dial_code: "+685",
    number: "685",
    name: "Samoa",
  },
  {
    code: "XK",
    dial_code: "+383",
    number: "383",
    name: "Kosovo",
  },
  {
    code: "YE",
    dial_code: "+967",
    number: "967",
    name: "Yemen",
  },
  {
    code: "YT",
    dial_code: "+262",
    number: "262",
    name: "Mayotte",
  },
  {
    code: "ZA",
    dial_code: "+27",
    number: "27",
    name: "South Africa",
  },
  {
    code: "ZM",
    dial_code: "+260",
    number: "260",
    name: "Zambia",
  },
  {
    code: "ZW",
    dial_code: "+263",
    number: "263",
    name: "Zimbabwe",
  },
];
