import { authAxios, adServerAxios } from "@/libs/axios";

import type {
  FetchAdsPayload,
  FetchAdsResponseData,
  FetchAdServerAdPayload,
  FetchAdServerAdResponseData,
  SubmitAdsClickEventPayload,
} from "./ad.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const adApi = {
  fetchAdServerAd: (payload: FetchAdServerAdPayload) => {
    return adServerAxios.get<FetchAdServerAdResponseData>("/delivery/ax.php", {
      params: payload.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchAds: (payload: FetchAdsPayload) => {
    return authAxios.get<AxiosResponseData<FetchAdsResponseData>>("ad/index", {
      params: payload.params,
      cancelToken: payload?.cancelToken,
    });
  },
  submitAdsClickEvent: (payload: SubmitAdsClickEventPayload) => {
    return authAxios.get<AxiosResponseData>("ad/index", {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default adApi;
