export const AUTH_USER_ID = "auth_user_id";
export const DEFAULT_AUTH_USER_REGION = "default_auth_user_region";
export const AUTH_USER_REGION = "auth_user_region";
export const AUTH_USER_REGION_ID = "auth_user_region_id";
export const AUTH_USER_LOCATION_LATITUDE = "auth_user_location_latitude";
export const AUTH_USER_LOCATION_LONGITUDE = "auth_user_location_longitude";
export const AUTH_TOKEN = "auth_token";
export const APP_LOCALE = "app_locale";

export const AUTH_WECHAT_SIGNIN_SESSION_ID = "auth_we_chat_signin_session_id";
export const AUTH_WECHAT_BINDING_SESSION_ID = "auth_we_chat_binding_session_id";

export const WECHAT_SIGN_IN_CALLBACK_RESPONSE =
  "wechat_sign_in_callback_response";
export const WECHAT_BINDING_CALLBACK_RESPONSE =
  "wechat_binding_callback_response";
export const IMPRESSION_PACKAGE_ORDER_PAYMENT_CALLBACK_RESPONSE =
  "impression_package_order_payment_callback_response";

export const BIND_EMAIL_VERIFIER_COUNTDOWN_END_TIME =
  "bind_email_verifier_countdown_end_time";

export const SEARCH_HISTORY = "search-history";

export const SIGN_UP_EMAIL_VERIFIER_COUNTDOWN_END_TIME =
  "sign_up_email_verifier_countdown_end_time";
export const RESET_PASSWORD_EMAIL_VERIFIER_COUNTDOWN_END_TIME =
  "reset_password_email_verifier_countdown_end_time";

export const RESET_PASSWORD_PHONE_VERIFIER_COUNTDOWN_END_TIME =
  "reset_password_phone_verifier_countdown_end_time";
export const BIND_PHONE_VERIFIER_COUNTDOWN_END_TIME =
  "bind_phone_verifier_countdown_end_time";

export const COOKIE_TOKEN = "cookie_token";

export const COOKIE_CONSENT = "CookieConsent";
