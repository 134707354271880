import axios from "axios";
import { commonConfig } from "@/utils/config";
import { xmlToJsonUtil } from "xml-to-json-util";

const adServerAxios = axios.create({
  baseURL: commonConfig.ADSERVER_ENDPOINT,
});

adServerAxios.interceptors.request.use(
  (req) => {
    if (typeof req.headers["Accept-Language"] === "undefined") {
      req.headers["Accept-Language"] =
        typeof window !== "undefined"
          ? window.NextPublic.lang.toLowerCase()
          : undefined;
    }
    // if (typeof req.headers["x-lang"] === "undefined") {
    //   req.headers["x-lang"] =
    //     typeof window !== "undefined"
    //       ? window.NextPublic.lang.toLowerCase()
    //       : undefined;
    // }

    // if (typeof req.headers["x-region"] === "undefined") {
    //   const regionId = appStorageService.getCookieAuthUserRegionId();
    //   req.headers["x-region"] = regionId || regionConstants.DEFAULT_REGION_ID;
    // }

    switch ((req.method as string).toUpperCase()) {
      case "GET": {
        req.params = req.params || {};
        break;
      }
      case "POST": {
        break;
      }
      case "PUT": {
        break;
      }
    }
    return req;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

adServerAxios.interceptors.response.use(
  (res) => {
    // if (!["", null, undefined].includes(res?.data?.error_code)) {
    // 	// helpers.axios.allocateRoute(res.data.error_code)
    // }

    if (
      res.headers["content-type"]?.toLowerCase().includes("application/xml")
    ) {
      res.data = xmlToJsonUtil(res.data);
    }

    return res;
  },
  (err) => {
    if (!axios.isCancel(err)) {
      // console.log(err);
    }
    return Promise.reject(err);
  }
);

export default adServerAxios;
