export const FIREBASE_NOTIFICATION_BACKGROUND_MESSAGE_CHANNEL_NAME =
  "firebase/FIREBASE_NOTIFICATION_BACKGROUND_MESSAGE";
export const FIREBASE_NOTIFICATION_CLICK_CHANNEL_NAME =
  "firebase/FIREBASE_NOTIFICATION_CLICK";

export const AUTH_EXPIRED_TOKEN_CHANNEL_NAME = "auth/AUTH_EXPIRED_TOKEN";

export const AUTH_SIGNED_IN_USER_CHANNEL_NAME = "auth/AUTH_SIGNED_IN_USER";

export const AUTH_SIGNED_OUT_USER_CHANNEL_NAME = "auth/AUTH_SIGNED_OUT_USER";
