export const IMPRESSION_PACKAGE_ORDER_CALLBACK_COMPLETED_PAYMENT_RESULT_NUMBER = 0;
export const IMPRESSION_PACKAGE_ORDER_CALLBACK_SUCCEEDED_PAYMENT_RESULT_NUMBER = 1;
export const IMPRESSION_PACKAGE_ORDER_CALLBACK_EXPIRED_PAYMENT_RESULT_NUMBER = 2;
export const IMPRESSION_PACKAGE_ORDER_CALLBACK_EMPTY_ORDER_RESULT_NUMBER = 3;
export const IMPRESSION_PACKAGE_ORDER_CALLBACK_FAILED_PAYMENT_RESULT_NUMBER = 4;
export const IMPRESSION_PACKAGE_ORDER_CALLBACK_ERROR_PAYMENT_RESULT_NUMBER = 5;

export const resultNumberToImpressionPackageOrderCallbackMessageMap = {
  [IMPRESSION_PACKAGE_ORDER_CALLBACK_COMPLETED_PAYMENT_RESULT_NUMBER]:
    "You have already paid",
  [IMPRESSION_PACKAGE_ORDER_CALLBACK_SUCCEEDED_PAYMENT_RESULT_NUMBER]:
    "Payment success",
  [IMPRESSION_PACKAGE_ORDER_CALLBACK_EXPIRED_PAYMENT_RESULT_NUMBER]:
    "Overdue payment",
  [IMPRESSION_PACKAGE_ORDER_CALLBACK_EMPTY_ORDER_RESULT_NUMBER]:
    "Order not found",
  [IMPRESSION_PACKAGE_ORDER_CALLBACK_FAILED_PAYMENT_RESULT_NUMBER]:
    "Failure to complete payment",
  [IMPRESSION_PACKAGE_ORDER_CALLBACK_ERROR_PAYMENT_RESULT_NUMBER]:
    "Payment error",
} as {
  [resultNumber: number]: string;
};
