import nextI18nextConfig from "@@/next-i18next.config";

export const NODE_ENV = process.env.NODE_ENV;

export const DOCUMENT_TITLE = "Swapifly";
export const DOCUMENT_DESCRIPTION =
  "Swapifly Limited is an award-winning Hong Kong-based online marketplace that specializes in the resale of rare and luxury items. Our platform leverages cutting-edge technologies such as A.I., ChatGPT, and big data to provide unparalleled listing competitiveness, consumer modeling, and A.I. sales services. With a focus on six key categories - Limited, Luxury, Electronics, Home & Beauty, Cars, and Property - Swapifly delivers high-quality consumer data to advertisers. Our recent accolades include winning the best AI classified platform at the HK Fintech Award in January 2023 and was chosen as the member company of Incubation Programme at Hong Kong Science and Technology Park in April 2023. In addition to our physical marketplace, Swapifly is also expanding into the metaverse by utilizing blockchain technology to facilitate virtual product exchanges. Swapifly now available in Hong Kong, Taiwan, Singapore, Japan, Korea, US, UK, Australia and Canada.";
export const APP_NAME = "swapifly";
export const APP_CACHE_KEY = "swapifly";
export const DEFAULT_PHONE_COUNTRY_CODE = "+852";
export const DEFAULT_SYSTEM_UTC_OFFSET = "+08:00";

export const FACEBOOK_PAGE_URL = process.env.NEXT_PUBLIC_FACEBOOK_PAGE_URL;
export const INSTAGRAM_PAGE_URL = process.env.NEXT_PUBLIC_INSTAGRAM_PAGE_URL;
export const LINKED_PAGE_URL = process.env.NEXT_PUBLIC_LINKED_PAGE_URL;

export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;
export const BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN;
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
export const CDN_HOST = process.env.NEXT_PUBLIC_CDN_HOST;
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const DEFAULT_LOCALE = nextI18nextConfig.i18n.defaultLocale;

export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET =
  process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET;
export const GOOGLE_ADS_CLIENT_ID =
  process.env.NEXT_PUBLIC_GOOGLE_ADS_CLIENT_ID;
export const GOOGLE_ANALYTICS_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID;

export const WECHAT_REDIRECT_URI = process.env.NEXT_PUBLIC_WECHAT_REDIRECT_URI;
export const WECHAT_APP_ID = process.env.NEXT_PUBLIC_WECHAT_APP_ID;
export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
export const GOOGLE_PLAY_APP_ID = process.env.NEXT_PUBLIC_GOOGLE_PLAY_APP_ID;
export const APPLE_APP_ID = process.env.NEXT_PUBLIC_APPLE_APP_ID;
export const APPLE_TEAM_ID = process.env.NEXT_PUBLIC_APPLE_TEAM_ID;
export const APPLE_BUNDLE_ID = process.env.NEXT_PUBLIC_APPLE_BUNDLE_ID;

export const ROBOTS_CONFIG = process.env.NEXT_PUBLIC_ROBOTS_CONFIG;
export const ADS_CONFIG = process.env.NEXT_PUBLIC_ADS_CONFIG;
export const APP_ADS_CONFIG = process.env.NEXT_PUBLIC_APP_ADS_CONFIG;

export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL =
  process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;
export const FIREBASE_MESSAGING_VAPID_KEY =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_VAPID_KEY;

export const WEBSOCKET_ENDPOINT = process.env.NEXT_PUBLIC_WEBSOCKET_ENDPOINT;

export const ADSERVER_ENDPOINT = process.env.NEXT_PUBLIC_ADSERVER_ENDPOINT;

export const DEEPLINK_SCHEME = process.env.NEXT_PUBLIC_DEEPLINK_SCHEME;
